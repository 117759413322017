'use client';

import {
	useActiveSection,
	useAnchorBarVisibility,
	useHorizontalScroll,
	useKeyboardNavigation,
} from '@/components/shared/AnchorBar/AnchorBar.hooks';
import { anchorBarRecipe } from '@/components/shared/AnchorBar/AnchorBar.recipe';
import { AnchorButton } from '@/components/shared/AnchorBar/components/AnchorBarButton';
import { ScrollToTopButton } from '@/components/shared/AnchorBar/components/ScrollToTopButton';
import { IAnchorBarElement } from '@/interfaces/coreInformation';
import { cx } from '@/styled-system/css';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { scrollbar } from '@/styled-system/recipes';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FC, useRef } from 'react';

export interface IAnchorBarItems extends IAnchorBarElement {
	sectionTitleHash: string;
	sectionBlockId?: string;
}

export interface IAnchorBarProps extends HTMLStyledProps<'div'> {
	translations: TranslationLabelValues;
	items: Array<IAnchorBarItems>;
	pageId?: number;
}

export const AnchorBar: FC<IAnchorBarProps> = ({ translations, items, pageId, ...rest }) => {
	const classes = anchorBarRecipe();
	const containerRef = useRef<HTMLDivElement>(null);
	const triggerContainerRef = useRef<HTMLUListElement>(null);

	const { isVisible } = useAnchorBarVisibility();
	const { activeSectionHash } = useActiveSection(items, containerRef);
	const { focusedIndex } = useKeyboardNavigation(triggerContainerRef);

	useHorizontalScroll(activeSectionHash, triggerContainerRef);

	return (
		<styled.div className={classes.positioner} data-visible={isVisible} {...rest}>
			<styled.div ref={containerRef} className={classes.container}>
				<styled.ul
					role="menubar"
					ref={triggerContainerRef}
					className={cx(classes.triggerContainer, scrollbar({ variant: 'hidden' }))}
				>
					{items.map((item, index) => (
						<styled.li role="none" key={item.sectionTitleHash} className={classes.triggerListItem}>
							<AnchorButton
								key={item.sectionTitleHash}
								className={classes.trigger}
								containerRef={containerRef}
								activeSectionHash={activeSectionHash}
								pageId={pageId}
								aria-haspopup="false"
								role="menuitem"
								tabIndex={index === focusedIndex ? 0 : -1}
								{...item}
							/>
						</styled.li>
					))}
				</styled.ul>

				<ScrollToTopButton
					label={translate(translations, 'anchorBar.top')}
					className={classes.scrollTopTrigger}
					onClick={() => window.scrollTo({ top: 0 })}
				/>
			</styled.div>
		</styled.div>
	);
};
