import { Navigation } from '@/components/shared/Navigation/Navigation';
import { INavigationSettings } from '@/components/shared/Navigation/Navigation.utils';
import {
	AppBannerBlockTemplate,
	IAppBannerBlockProps,
} from '@/components/block-templates/AppBannerBlockTemplate/AppBannerBlockTemplate';
import { ContentContainer } from '@/components/shared/ContentContainer/ContentContainer';
import { IHeaderSettings } from '@/components/shared/Header/Header.utils';
import { HeaderButtons } from '@/components/shared/Header/components/HeaderButtons/HeaderButtons';
import { LoginNavigation } from '@/components/shared/LoginNavigation/LoginNavigation';
import { Logo } from '@/components/shared/Logo/Logo';
import { LanguageSelect } from '@/components/shared/language/LanguageSelect';
import { SearchInput } from '@/components/shared/searchInput/SearchInput';
import { SiteConfig } from '@/constants/siteConfig';
import { SelectTriggerLabelType } from '@/enums/Select';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IBlockResponse, IPageLanguage } from '@/interfaces/coreInformation';
import { css } from '@/styled-system/css';
import { Box, FlexProps, HStack } from '@/styled-system/jsx';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { ILanguageSettings } from '@/utils/language';
import { FC, ReactNode } from 'react';
import { HeaderContainer } from '@/components/shared/Header/components/HeaderContainer/HeaderContainer';
import { HeaderProvider } from '@/components/shared/Header/components/HeaderProvider/HeaderProvider';
import { PreviewModeBanner } from '@/components/shared/PreviewModeBanner/PreviewModeBanner';
import { LoginNavigationProvider } from '@/components/shared/LoginNavigation/LoginNavigationProvider';

interface IHeaderProps extends FlexProps, ITranslatableComponentProps {
	appBannerBlockDefinition?: IBlockResponse<IAppBannerBlockProps>;
	forceCulture?: string;
	headerSettings?: IHeaderSettings;
	navigationSettings?: INavigationSettings;
	languageSettings: ILanguageSettings;
	existingLanguages: Array<IPageLanguage>;
	currentLanguage: string;
	siteConfig: SiteConfig;
	anchorBarSlot?: ReactNode;
}

const languageSelectClassName = css({
	border: 'solid 1px transparent',
	margin: 'auto',
	width: 'min-content',
	whiteSpace: 'nowrap',
	textTransform: 'uppercase',
	pointerEvents: 'auto',
	'&[aria-expanded="true"]': {
		border: 'solid 1px',
		borderColor: 'other.border.regular.strong',
	},
	'&[data-selected="true"]': {
		fontWeight: 'regular',
	},
	_print: {
		display: 'none',
	},
});

const languageSelectContentClassName = css({
	'&[data-part="content"]': {
		minWidth: '180px',
	},
});

export const HEADER_ID = 'header-nav';

export const Header: FC<IHeaderProps> = ({
	appBannerBlockDefinition,
	headerSettings,
	navigationSettings,
	forceCulture,
	translations,
	languageSettings,
	existingLanguages,
	currentLanguage = 'en',
	siteConfig,
	anchorBarSlot,
}) => {
	return (
		<HeaderProvider>
			<HeaderContainer id={HEADER_ID}>
				<PreviewModeBanner />
				{appBannerBlockDefinition && <AppBannerBlockTemplate blockDefinition={appBannerBlockDefinition} />}

				<ContentContainer display="flex" height="headerHeight" paddingX={{ base: 6, md: 8, xl: 0 }}>
					<Logo locale={currentLanguage} />

					<LoginNavigationProvider>
						<HStack flex="3" justifyContent="flex-end" gap={4}>
							<HStack hideBelow="md" gap={2}>
								{headerSettings?.headerButtons && <HeaderButtons buttons={headerSettings.headerButtons} />}
								{headerSettings && (
									<LoginNavigation
										translations={translations}
										quickAccessMenu={headerSettings.quickAccessMenu}
										displayEvolutionSystem={siteConfig.displayLoginAndNavigationEvolutionSystem}
									/>
								)}
							</HStack>
							<HStack gap={3}>
								<Box display="contents" hideBelow="md">
									{headerSettings?.aiSearch && (
										<SearchInput
											searchUrl={translate(translations, 'pages.search.path')}
											placeholder={translations['header.searchPlaceholder']}
										/>
									)}
									<LanguageSelect
										forceCulture={forceCulture}
										languageSettings={languageSettings}
										existingLanguages={existingLanguages}
										translations={translations}
										contentClassName={languageSelectContentClassName}
										selectTriggerLabelType={SelectTriggerLabelType.VALUE}
										variant="defaultLight"
										className={languageSelectClassName}
										positioning={{ placement: 'bottom-start' }}
										hideOnEmpty={true}
										id="header__language-select"
									/>
								</Box>
								<Navigation
									forceCulture={forceCulture}
									languageSettings={languageSettings}
									existingLanguages={existingLanguages}
									translations={translations}
									navigationItems={navigationSettings?.navigation}
									mainNavigationHomeClaim={headerSettings?.mainNavigationHomeClaim?.[0]?.contentLink?.expanded?.content}
									mainNavigationQuickLinks={headerSettings?.mainNavigationQuickLinks}
									displayEvolutionSystem={siteConfig.displayLoginAndNavigationEvolutionSystem}
								/>
							</HStack>
						</HStack>
					</LoginNavigationProvider>
				</ContentContainer>

				{anchorBarSlot}
			</HeaderContainer>
		</HeaderProvider>
	);
};
