'use client';

import { FC, RefObject, useRef } from 'react';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { IAnchorBarItems } from '@/components/shared/AnchorBar/AnchorBar';
import { HEADER_ID } from '@/components/shared/Header/Header';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { ITrackedBlockInformation } from '@/types/tracking';

interface IAnchorButtonProps extends Omit<HTMLStyledProps<'button'>, 'id' | 'name'>, IAnchorBarItems {
	containerRef: RefObject<HTMLDivElement>;
	activeSectionHash: string | null;
	pageId?: number;
}

export const AnchorButton: FC<IAnchorButtonProps> = ({
	id,
	name,
	className,
	sectionTitleHash,
	sectionTitle,
	sectionBlockId,
	activeSectionHash,
	containerRef,
	conversionId,
	pageId,
	...rest
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	return (
		<Tracked
			trackingElementRef={buttonRef}
			cmsTrackingInformation={{
				techCategory: 'tab',
				category: 'sidebar',
				action: 'click',
				label: `${pageId}|${sectionTitle}`,
				conversionId: conversionId || '',
				nonInteraction: false,
			}}
			trackedBlockInformation={
				{
					blockInstanceId: 'AnchorBar',
					blockInstanceName: 'AnchorBar',
					blockIsRelevant: true,
				} as ITrackedBlockInformation
			}
		>
			<styled.button
				ref={buttonRef}
				className={className}
				id={`trigger-${sectionTitleHash}`}
				data-selected={sectionTitleHash === activeSectionHash}
				onClick={() => {
					const sectionElement = document.getElementById(sectionBlockId || sectionTitleHash);

					if (sectionElement) {
						const { top } = sectionElement.getBoundingClientRect();
						const headerNavHeight = document.getElementById(HEADER_ID)?.clientHeight || 0;
						const anchorBarHeight = containerRef.current?.clientHeight || 0;
						const headerHeight = headerNavHeight + anchorBarHeight;

						window.scrollTo({
							top: window.scrollY + top - headerHeight,
						});
					}
				}}
				{...rest}
			>
				{sectionTitle}
			</styled.button>
		</Tracked>
	);
};
