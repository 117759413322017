import { IAnchorBarItems } from '@/components/shared/AnchorBar/AnchorBar';
import { HEADER_ID } from '@/components/shared/Header/Header';
import { HERO_ID } from '@/components/shared/Hero/Hero';
import throttle from 'lodash/throttle';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { RefObject, useEffect, useState } from 'react';

const calculateAnchorBarVisibility = () => {
	const heroContainerHeight = document.getElementById(HERO_ID)?.clientHeight || 400;
	const headerNavHeight = document.getElementById(HEADER_ID)?.clientHeight || 0;

	return window.scrollY > heroContainerHeight - headerNavHeight;
};

export const useAnchorBarVisibility = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		setIsVisible(calculateAnchorBarVisibility());
	}, []);

	useEffect(() => {
		const handleVisibility = throttle(() => {
			const isAnchorBarVisible = calculateAnchorBarVisibility();

			if (isAnchorBarVisible !== isVisible) {
				setIsVisible(isAnchorBarVisible);
			}
		}, 200);

		window.addEventListener('scroll', handleVisibility);

		return () => {
			window.removeEventListener('scroll', handleVisibility);
		};
	}, [isVisible]);

	return { isVisible };
};

const getMaxScrollPosition = () => {
	const scrollHeight = document.documentElement.scrollHeight;
	const clientHeight = document.documentElement.clientHeight;

	return scrollHeight - clientHeight;
};

const findClosestVisibleSection = (items: Array<IAnchorBarItems>) => {
	let closestSection = null;
	let closestTop = Infinity;

	items.forEach(({ sectionBlockId, sectionTitleHash }) => {
		const section = document.getElementById(sectionBlockId || sectionTitleHash);

		if (section) {
			const { top } = section.getBoundingClientRect();

			if (top >= 0 && top < closestTop) {
				closestTop = top;
				closestSection = sectionTitleHash;
			}
		}
	});

	return closestSection;
};

export const useActiveSection = (items: Array<IAnchorBarItems>, containerRef: RefObject<HTMLDivElement>) => {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [activeSectionHash, setActiveSectionHash] = useState<string | null>(null);

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		setActiveSectionHash(window.location.hash.slice(1) || null);
	}, []);

	const isSectionVisible = (id: string): boolean => {
		const section = document.getElementById(id);

		if (section) {
			const { top, bottom } = section.getBoundingClientRect();

			const headerNavHeight = document.getElementById(HEADER_ID)?.clientHeight || 0;
			const anchorBarHeight = containerRef.current?.clientHeight || 0;
			const headerHeight = headerNavHeight + anchorBarHeight;

			return Math.floor(top) <= headerHeight && Math.floor(bottom) >= headerHeight;
		}

		return false;
	};

	const updateActiveSection = () => {
		let visibleSectionHash: string | null = null;

		items.forEach(({ sectionBlockId, sectionTitleHash }) => {
			if (isSectionVisible(sectionBlockId || sectionTitleHash)) {
				visibleSectionHash = sectionTitleHash;
			}
		});

		const isScrolledToBottom = getMaxScrollPosition() === window.scrollY;

		if (isScrolledToBottom) {
			const closestSection = findClosestVisibleSection(items);

			if (closestSection) {
				visibleSectionHash = closestSection;
			}
		}

		const currentHash = window.location.hash.slice(1);
		const isHashForVisibleSection = items.some(({ sectionTitleHash }) => sectionTitleHash === currentHash);
		const url = new URL(pathname, window.location.origin);

		url.hash = visibleSectionHash || (isHashForVisibleSection ? '' : currentHash);
		url.search = searchParams.toString();

		setActiveSectionHash(visibleSectionHash);

		// If the pathname is different from the current pathname, we don't want to update the URL (nextjs performed soft navigation)
		if (url.pathname.toString() !== window.location.pathname.toString()) {
			return;
		}

		if (url.toString() !== window.location.href) {
			router.replace(url.toString(), { scroll: false });
		}
	};

	useEffect(() => {
		const throttledScrollHandler = throttle(updateActiveSection, 200);

		window.addEventListener('scroll', throttledScrollHandler);

		return () => window.removeEventListener('scroll', throttledScrollHandler);
	}, []);

	return { activeSectionHash };
};

export const useHorizontalScroll = (
	activeSectionHash: string | null,
	triggerContainerRef: RefObject<HTMLUListElement>
) => {
	useEffect(() => {
		const activeTrigger = document.getElementById(`trigger-${activeSectionHash}`);
		const triggerContainer = triggerContainerRef.current;

		if (activeTrigger && triggerContainer) {
			triggerContainer.scroll({
				left: activeTrigger.offsetLeft - triggerContainer?.offsetLeft,
				behavior: 'smooth',
			});
		}
	}, [activeSectionHash]);
};

export const useKeyboardNavigation = (triggerContainerRef: RefObject<HTMLUListElement>) => {
	const [focusedIndex, setFocusedIndex] = useState<number>(0);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const focusableElements = triggerContainerRef.current?.querySelectorAll('[role="menuitem"]');

			if (!focusableElements) {
				return;
			}

			const currentIndex = Array.from(focusableElements).indexOf(document.activeElement as Element);
			let newIndex = currentIndex;

			switch (event.key) {
				case 'ArrowRight':
					newIndex = (currentIndex + 1) % focusableElements.length;
					break;
				case 'ArrowLeft':
					newIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
					break;
				case 'Home':
					newIndex = 0;
					break;
				case 'End':
					newIndex = focusableElements.length - 1;
					break;
				default:
					return;
			}

			setFocusedIndex(newIndex);
			(focusableElements[newIndex] as HTMLElement).focus();
			event.preventDefault();
		};

		triggerContainerRef.current?.addEventListener('keydown', handleKeyDown);

		return () => {
			triggerContainerRef.current?.removeEventListener('keydown', handleKeyDown);
		};
	}, [triggerContainerRef]);

	return { focusedIndex };
};
