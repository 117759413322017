'use client';

import { Button } from '@/components/core/Button/Button';
import { Heading } from '@/components/core/Heading/Heading';
import { ContentContainer } from '@/components/shared/ContentContainer/ContentContainer';
import { ButtonsToHide, IDisclaimerFunctionality, IDisplaySettings } from '@/components/shared/Disclaimer/Disclaimer';
import {
	TealiumConsentState,
	acceptCookies,
	getConsentState,
	tealiumConsentExists,
} from '@/components/shared/Disclaimer/Disclaimer.utils';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { CloseIcon } from '@/icons/CloseIcon';
import { Box, BoxProps, Flex, VStack } from '@/styled-system/jsx';
import { logger } from '@/utils/logger';
import { CustomTrackingEvent, doTrack } from '@/utils/tracking/tracking';
import { usePathname, useRouter } from 'next/navigation';
import { RefObject, useEffect, useMemo, useState } from 'react';

interface ICookieDisclaimerProps extends BoxProps {
	displaySettings: IDisplaySettings;
	disclaimerFunctionality: IDisclaimerFunctionality;
	displayTogether?: boolean;
	setIsOpen: (isOpen: boolean) => void;
	setSettingsOpen: (settingsOpen: boolean) => void;
	translations: Record<string, string>;
	language: string;
	trackingRef: RefObject<HTMLDivElement>;
	customTermsCookieKey?: string;
	onSubmit?: () => Promise<void>;
	disabled?: boolean;
	containerClassName?: string;
}

export const CookieDisclaimer = (props: ICookieDisclaimerProps) => {
	const {
		displaySettings,
		disclaimerFunctionality,
		displayTogether,
		setIsOpen,
		setSettingsOpen,
		translations,
		language,
		trackingRef,
		customTermsCookieKey,
		disabled,
		onSubmit,
		containerClassName,
		...rest
	} = props;
	const router = useRouter();
	const currentPath = usePathname();

	const [isFunctionalButtonLoading, setIsFunctionalButtonLoading] = useState(false);
	const [isAcceptButtonLoading, setIsAcceptButtonLoading] = useState(false);

	const buttonsToHide = displaySettings?.buttonsToHide?.split(',');
	const disclaimerOptions = disclaimerFunctionality?.disclaimerOptions?.split(',');

	const handleFunctionalOnly = async () => {
		if (!trackingRef.current) {
			logger.debug('Accepted functional cookies, but no element to attach the tracking event to could be found.');

			return;
		}
		setIsFunctionalButtonLoading(true);

		await onSubmit?.();

		acceptCookies(trackingRef.current, TealiumConsentState.FunctionalOnly);

		setIsOpen(false);
		setIsFunctionalButtonLoading(false);
	};

	const handleAccept = async () => {
		if (!trackingRef.current) {
			logger.debug('Accepted functional cookies, but no element to attach the tracking event to could be found.');

			return;
		}
		setIsAcceptButtonLoading(true);

		const consentState = getConsentState();

		await onSubmit?.();

		acceptCookies(
			trackingRef.current,
			consentState === TealiumConsentState.None ? TealiumConsentState.AcceptedAll : consentState
		);

		setIsOpen(false);
		setIsAcceptButtonLoading(false);
	};

	const close = () => {
		acceptCookies(trackingRef.current!, TealiumConsentState.FunctionalOnly);
		setIsOpen(false);
	};

	const handleDecline = async () => {
		const redirectUrl = disclaimerFunctionality.declineButtonRedirectLink;

		if (redirectUrl) {
			if (redirectUrl === currentPath) {
				location.reload();
			} else {
				router.push(redirectUrl);
			}
		}
		setIsOpen(false);
	};

	const isButtonHidden =
		buttonsToHide?.includes(ButtonsToHide.FUNCTIONAL_ONLY) || buttonsToHide?.includes(ButtonsToHide.ACCEPT);

	const handleSettingsOpen = () => {
		setSettingsOpen(true);
		setIsOpen(false);

		if (trackingRef?.current) {
			doTrack(CustomTrackingEvent.FromTarget(trackingRef.current, 'settingsShow'));
		}
	};

	// Only decide on initial load, whether functional button should be displayed
	const showFunctional: boolean = useMemo(() => {
		return !buttonsToHide?.includes(ButtonsToHide.FUNCTIONAL_ONLY) && !tealiumConsentExists();
	}, []);

	useEffect(() => {
		const cookieSettingsLink = document.querySelector('.cookieSettingsTrigger') as HTMLElement;

		cookieSettingsLink?.addEventListener('click', handleSettingsOpen);
	});

	const getAcceptButtonText = (): string => {
		return getConsentState() === TealiumConsentState.AcceptedPartially || tealiumConsentExists()
			? translations['disclaimer.button.accept.text']
			: translations['disclaimer.button.acceptAll.text'];
	};

	return (
		<>
			<Box {...rest}>
				<ContentContainer providePadding={false} className={containerClassName}>
					<Flex direction={{ base: 'column', md: 'row' }} justify="space-between" gap={{ base: 6, md: 8, lg: 12 }}>
						<VStack className="Introduction" gap={2} alignItems="left">
							{!displayTogether && <Heading as="h5">{displaySettings.disclaimerTitleText}</Heading>}
							{displaySettings.disclaimerCookieSectionText && (
								<TextSnippet
									id="disclaimerCookieSectionText"
									content={displaySettings.disclaimerCookieSectionText}
									parentBackgroundColor={BackgroundColorVariant.PRIMARY}
									shouldBindCookieSettingsTrigger={false}
									dataId="disclaimer-cookie-section-text"
								/>
							)}
						</VStack>
						<VStack className={`Buttons ${isButtonHidden ? '-hidden' : ''}`} gap={2}>
							{!buttonsToHide?.includes(ButtonsToHide.DECLINE) && disclaimerFunctionality.declineButtonRedirectLink && (
								<Button
									onClick={handleDecline}
									variant="secondary"
									size="large"
									title={translations['disclaimer.button.decline.title']}
									disabled={disabled}
								>
									{translations['disclaimer.button.decline.text']}
								</Button>
							)}
							{showFunctional && (
								<Button
									onClick={handleFunctionalOnly}
									variant="secondary"
									title={translations['disclaimer.button.functional.title']}
									disabled={disabled}
									isLoading={isFunctionalButtonLoading}
								>
									{translations['disclaimer.button.functional.text']}
								</Button>
							)}
							{!buttonsToHide?.includes(ButtonsToHide.ACCEPT) && (
								<Button
									id="accept-cookies-button"
									onClick={handleAccept}
									variant="primary"
									title={translations['disclaimer.button.accept.title']}
									disabled={disabled}
									isLoading={isAcceptButtonLoading}
								>
									{getAcceptButtonText()}
								</Button>
							)}
						</VStack>
					</Flex>
				</ContentContainer>
				{disclaimerOptions?.includes('FunctionalOnlyOnClose') && (
					<Button
						className="CloseButton"
						onClick={() => close()}
						variant="unstyled"
						size="small"
						leftIcon={<CloseIcon width={16} height={16} />}
					/>
				)}
			</Box>
		</>
	);
};
